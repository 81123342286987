import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => <Seo title="A propos" noindex />

const AboutPage = () => (
  <Layout content="page">
    <h1>A propos</h1>
    <h2>Paris asiatique</h2>
    <p>
      Paris asiatique aide les restaurants asiatiques dans leur stratégie web et
      mobile : création de site vitrine, plateforme de réservation, site
      e-commerce, optimisation du référencement Seo, etc.
    </p>
    <p>
      Nous avons animé pendant 8 ans un site de bonnes adresses de restaurants
      asiatiques à Paris. Nous mettons désormais cette expérience à votre
      service.
    </p>
    <h2>Joachim</h2>
    <p>
      Au quotidien, je développe des sites internet et des applications mobiles
      en freelance sous le nom{" "}
      <a
        title="Solygambas"
        href="https://solygambas.github.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Solygambas
      </a>
      .
    </p>
    <h2>Vous voulez travailler avec Paris asiatique ?</h2>
    <p>
      Pour nous écrire, merci d’utiliser la{" "}
      <Link to="/contact" title="Contact">
        page contact
      </Link>
      .
    </p>
    <p>
      <Link to="/" title="Paris asiatique">
        Revenir à l'accueil
      </Link>
    </p>
  </Layout>
)

export default AboutPage
